import {
    createContext,
    useState,
    useContext
} from 'react';

const cuentaContexto = createContext();
const setCuentaContexto = createContext();

export function useCuentaContexto() {
    return useContext(cuentaContexto);
}

export function useSetCuentaContexto() {
    return useContext(setCuentaContexto);
}

export function CuentaProveedor(props) {
    const [cuenta, setCuenta] = useState(
        {
            token: '',
            nombre: '',
            apellido: '',
            rol: '',
            interfaces: []
        }
    );

    function establecerCuenta(cuentaActual) {
        setCuenta(cuentaActual);
    }

    return (
        <cuentaContexto.Provider value={cuenta}>
            <setCuentaContexto.Provider value={establecerCuenta}>
                {props.children}
            </setCuentaContexto.Provider>
        </cuentaContexto.Provider>
    );
}