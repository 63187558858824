//------------------------------------------------------------------Backend

var urlBackend = process.env.REACT_APP_BACKEND_URL;
var urlSyslogBackend = process.env.REACT_APP_SYSLOG_URL;

var ambienteApp = process.env.REACT_APP_AMBIENTE_APP;

module.exports = {
    urlBackend,
    urlSyslogBackend,
    ambienteApp
};