import {
    useState,
    useEffect
} from 'react';

import {
    urlBackend
} from '../recursos/configuracion';

import {
    generarCodigoAlfanumerico
} from '../recursos/funcion';

import Axios from 'axios';
import {
    Container,
    Col,
    Row,
    Dropdown,
    Table,
    Button,
    Modal,
    FloatingLabel,
    Form,
    Stack,
    ListGroup,
    Badge,
    InputGroup
} from 'react-bootstrap';
import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome';
import {
    faCogs,
    faPlus,
    faRefresh,
    faEdit,
    faLock,
    faCog,
    faTimes,
    faCheck,
    faCode,
    faTrash,
    faWindowRestore,
    faUserGear,
    faCertificate
} from '@fortawesome/free-solid-svg-icons';
import {
    Cargador
} from '../componentes/Cargador';
import {
    reportar
} from '../recursos/funcion';
import Swal from 'sweetalert2';
import {
    useCuentaContexto
} from '../proveedor/cuenta';

export default function Sistemas() {

    const cuenta = useCuentaContexto();

    const [nombreSistema, setNombreSistema] = useState('');
    const [datos, setDatos] = useState([]);

    const [nombreRol, setNombreRol] = useState('');
    const [roles, setRoles] = useState([]);

    const [nombreInterfaz, setNombreInterfaz] = useState('');
    const [interfaces, setInterfaces] = useState([]);

    const [permisos, setPermisos] = useState([]);

    const [carga, setCarga] = useState(false);

    const [ruta, setRuta] = useState('');

    const [posicionRol, setPosicionRol] = useState(-1);
    const [posicionInterfaz, setPosicionInterfaz] = useState(-1);
    const [posicionPermiso, setPosicionPermiso] = useState(-1);

    const [modalDatos, setModalDatos] = useState(false);

    const mostrarModalDatos = () => setModalDatos(true);
    const cerrarModalDatos = () => setModalDatos(false);

    const [modalRol, setModalRol] = useState(false);

    const mostrarModalRol = () => setModalRol(true);
    const cerrarModalRol = () => setModalRol(false);

    const [modalInterfaz, setModalInterfaz] = useState(false);

    const mostrarModalInterfaz = () => setModalInterfaz(true);
    const cerrarModalInterfaz = () => setModalInterfaz(false);

    const [modalPermiso, setModalPermiso] = useState(false);

    const mostrarModalPermiso = () => setModalPermiso(true);
    const cerrarModalPermiso = () => setModalPermiso(false);

    const [nombre, setNombre] = useState('');
    const [direccion, setDireccion] = useState('');
    const [validez, setValidez] = useState(0);

    const [accion, setAccion] = useState(0);

    useEffect(() => {
        recuperar();
    }, [cuenta]);

    async function recuperar() {
        if (cuenta.token !== "") {
            setCarga(true);
            try {
                let respuesta = await Axios.get(`${urlBackend}/sistemas`, {
                    headers: {
                        Authorization: `Bearer ${cuenta.token}`
                    }
                });
                setDatos(respuesta.data.arrayJson);
                setCarga(false);
            } catch (error) {
                setCarga(false);
                await reportar('Sin definir', "Ecommerce Auth APP", "Frontend", "sistemas.jsx", "recuperar", `${error.toString()}`, {
                    error
                }, 1);
            }
        }
    }

    function nuevo() {
        setAccion(1);
        mostrarModalDatos();
    }

    async function guardarDatos() {
        try {
            let nombre = document.getElementById('txtNombre').value;
            let url = document.getElementById('txtUrl').value;
            let validez = document.getElementById('txtValidez').value;
            if (nombre === "" || url === "") {

            } else {
                let respuesta = {};
                let parametros = {
                    nombre: nombre,
                    url: url,
                    validez: validez
                }
                if (accion === 1) {
                    respuesta = await Axios.post(`${urlBackend}/sistemas`, parametros, {
                        headers: {
                            Authorization: `Bearer ${cuenta.token}`
                        }
                    });
                } else {
                    respuesta = await Axios.put(`${urlBackend}/sistemas?ruta=${ruta}`, parametros, {
                        headers: {
                            Authorization: `Bearer ${cuenta.token}`
                        }
                    });
                }
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: respuesta.data.descripcion
                })
                recuperar();
                cerrarModalDatos();
                setAccion(0);
            }
        } catch (error) {
            await reportar('Sin definir', "Ecommerce Auth APP", "Frontend", "sistemas.jsx", "guardarDatos", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    function cancelarDatos() {
        cerrarModalDatos();
    }

    async function guardarRol() {
        try {
            let parametros = {
                rol: roles
            }
            let respuesta = await Axios.put(`${urlBackend}/sistemas?ruta=${ruta}`, parametros, {
                headers: {
                    Authorization: `Bearer ${cuenta.token}`
                }
            });
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: respuesta.data.descripcion
            })
            recuperar();
            cerrarModalRol();
        } catch (error) {
            await reportar('Sin definir', "Ecommerce Auth APP", "Frontend", "sistemas.jsx", "guardarRol", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    function cancelarRol() {
        setPosicionRol(-1);
        cerrarModalRol();
    }

    async function guardarInterfaz() {
        let agregarRol = [...roles];
        agregarRol[posicionRol].interfaz = interfaces;
        setRoles(agregarRol);
        cerrarModalInterfaz();
        mostrarModalRol();
    }

    function cancelarInterfaz() {
        setPosicionInterfaz(-1);
        cerrarModalInterfaz();
        mostrarModalRol();
    }

    function guardarPermiso() {
        let agregarRol = [...roles];
        agregarRol[posicionRol].interfaz[posicionInterfaz].permiso = permisos;
        setRoles(agregarRol);
        cerrarModalPermiso();
        mostrarModalInterfaz();
    }

    function cancelarPermiso() {
        setPosicionPermiso(-1);
        cerrarModalPermiso();
        mostrarModalInterfaz();
    }

    async function editar(ruta) {
        setAccion(2);
        setRuta(ruta);
        try {
            let resultado = await Axios.get(`${urlBackend}/sistemas?ruta=${ruta}`, {
                headers: {
                    Authorization: `Bearer ${cuenta.token}`
                }
            });
            setNombre(resultado.data.objetoJson.nombre);
            setDireccion(`${resultado.data.objetoJson.protocolo}//${resultado.data.objetoJson.direccion}:${resultado.data.objetoJson.puerto}`);
            setValidez(resultado.data.objetoJson.validez);
            mostrarModalDatos();
        } catch (error) {
            console.log(error);
            await reportar('Sin definir', "Ecommerce Auth APP", "Frontend", "sistemas.jsx", "editar", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    async function manejarRol(nombre, roles, ruta) {
        setRuta(ruta);
        setNombreSistema(nombre);
        setRoles(roles);
        mostrarModalRol();
    }

    async function cambiarLlave(ruta) {
        try {
            let parametros = {
                llave: 0
            }
            let respuesta = await Axios.put(`${urlBackend}/sistemas?ruta=${ruta}`, parametros, {
                headers: {
                    Authorization: `Bearer ${cuenta.token}`
                }
            });
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: respuesta.data.descripcion
            })
            recuperar();
        } catch (error) {
            console.log(error);
            await reportar('Sin definir', "Ecommerce Auth APP", "Frontend", "sistemas.jsx", "cambiarLlave", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    async function estado(ruta, estado) {
        try {
            let parametros = {
                estado: (estado === 0 ? 1 : 0)
            }
            let respuesta = await Axios.put(`${urlBackend}/sistemas?ruta=${ruta}`, parametros, {
                headers: {
                    Authorization: `Bearer ${cuenta.token}`
                }
            });
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: respuesta.data.descripcion
            })
            recuperar();
        } catch (error) {
            await reportar('Sin definir', "Ecommerce Auth APP", "Frontend", "sistemas.jsx", "estado", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    function agregarRol() {
        if (document.getElementById('txtRol').value === '') {

        } else {
            let agregarRol = [...roles];
            agregarRol.push(
                {
                    codigo: generarCodigoAlfanumerico(10),
                    nombre: document.getElementById('txtRol').value,
                    interfaz: []
                }
            );
            setRoles(agregarRol);
            document.getElementById('txtRol').value = '';
        }
    }

    function quitarRol() {
        if (posicionRol === -1) {

        } else {
            let quitarRol = [...roles];
            quitarRol.splice(posicionRol, 1);
            setRoles(quitarRol);
            setPosicionRol(-1);
        }
    }

    function manejarInterfaz() {
        if (posicionRol === -1) {

        } else {
            setNombreRol(roles[posicionRol].nombre);
            setInterfaces(roles[posicionRol].interfaz);
            cerrarModalRol();
            mostrarModalInterfaz();
        }
    }

    function agregarInterfaz() {
        if (document.getElementById('txtInterfaz').value === '') {

        } else {
            let agregarInterfaz = [...interfaces];
            agregarInterfaz.push(
                {
                    nombre: document.getElementById('txtInterfaz').value,
                    permiso: [
                        "leer",
                        "crear",
                        "modificar",
                        "eliminar"
                    ]
                }
            );
            setInterfaces(agregarInterfaz);
            document.getElementById('txtInterfaz').value = '';
        }
    }

    function quitarInterfaz() {
        if (posicionInterfaz === -1) {

        } else {
            let quitarInterfaz = [...interfaces];
            quitarInterfaz.splice(posicionInterfaz, 1);
            setInterfaces(quitarInterfaz);
            setPosicionInterfaz(-1);
        }
    }

    function manejarPermiso() {
        if (posicionInterfaz === -1) {

        } else {
            let agregarRol = [...roles];
            agregarRol[posicionRol].interfaz = interfaces;
            setRoles(agregarRol);
            setNombreInterfaz(interfaces[posicionInterfaz].nombre);
            setPermisos(interfaces[posicionInterfaz].permiso);
            cerrarModalInterfaz();
            mostrarModalPermiso();
        }
    }

    function agregarPermiso() {
        if (document.getElementById('txtPermiso').value === '') {

        } else {
            let agregarPermiso = [...permisos];
            agregarPermiso.push(document.getElementById('txtPermiso').value);
            setPermisos(agregarPermiso);
            document.getElementById('txtPermiso').value = '';
        }
    }

    function quitarPermiso() {
        if (posicionPermiso === -1) {

        } else {
            let quitarPermiso = [...permisos];
            quitarPermiso.splice(posicionPermiso, 1);
            setPermisos(quitarPermiso);
            setPosicionPermiso(-1);
        }
    }

    const eventoEnterDatos = (event) => {
        if (event.key === 'Enter') {
            guardarDatos();
        }
    }

    return (
        <>
            <Container fluid>
                <Row className='mt-3 mb-3'>
                    <Col md={12}>
                        <h3>
                            {(carga === true ? <Cargador estado={true} /> : <FontAwesomeIcon icon={faCode} />)}Sistemas
                        </h3>
                    </Col>
                    <Col md={12} className='mt-3 mb-3'>
                        <Dropdown data-bs-theme="dark">
                            <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                                <FontAwesomeIcon icon={faCogs} />Opciones
                            </Dropdown.Toggle>
                            <Dropdown.Menu variant="dark">
                                <Dropdown.Item onClick={nuevo}>
                                    <FontAwesomeIcon icon={faPlus} />Nuevo
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={recuperar}>
                                    <FontAwesomeIcon icon={faRefresh} />Actualizar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col md={12} className='table-responsive'>
                        <Table bordered className='text-white'>
                            <thead>
                                <tr>
                                    <th>Acción</th>
                                    <th>Nombre</th>
                                    <th>Dirección</th>
                                    <th>Roles</th>
                                    <th>Validez Token</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    datos.map((dato, llave) => (
                                        <tr key={llave} className={(dato.estado === 0 ? 'text-danger' : 'text-success')}>
                                            <td>
                                                <Dropdown data-bs-theme="dark">
                                                    <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                                                        <FontAwesomeIcon icon={faCog} />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu variant="dark">
                                                        <Dropdown.Item onClick={() => {
                                                            editar(dato.ruta);
                                                        }}>
                                                            <FontAwesomeIcon icon={faEdit} />Editar
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={() => {
                                                            manejarRol(dato.nombre, dato.rol, dato.ruta);
                                                        }}>
                                                            <FontAwesomeIcon icon={faUserGear} />Roles
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={() => {
                                                            cambiarLlave(dato.ruta);
                                                        }}>
                                                            <FontAwesomeIcon icon={faLock} />Cambiar llave
                                                        </Dropdown.Item>
                                                        <Dropdown.Divider />
                                                        <Dropdown.Item onClick={() => {
                                                            estado(dato.ruta, dato.estado);
                                                        }}>
                                                            <FontAwesomeIcon icon={faRefresh} />Estado
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                            <td>{dato.nombre}</td>
                                            <td>{`${dato.protocolo}//`}{dato.direccion}{(dato.puerto === null ? '' : `:${dato.puerto}`)}</td>
                                            <td>{dato.rol.length}</td>
                                            <td>{`${dato.validez} minutos`}</td>
                                            <td>{(dato.estado === 1 ? <><FontAwesomeIcon icon={faCheck} />Activo</> : <><FontAwesomeIcon icon={faTimes} />Inactivo</>)}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>

            <Modal show={modalDatos} onHide={cancelarDatos} backdrop="static" keyboard={false}>
                <Modal.Header closeButton className='bg-dark text-white'>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faCode} /> Sistema
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-dark text-white'>
                    <FloatingLabel controlId="txtNombre" label="Nombre" className="text-dark mb-3" onKeyUp={eventoEnterDatos}>
                        <Form.Control type="text" placeholder="Nombre" defaultValue={nombre} />
                    </FloatingLabel>
                    <FloatingLabel controlId="txtUrl" label="Dirección" className="text-dark mb-3" onKeyUp={eventoEnterDatos}>
                        <Form.Control type="text" placeholder="Dirección" defaultValue={direccion} />
                    </FloatingLabel>
                    <FloatingLabel controlId="txtValidez" label="Validez Token" className="text-dark mb-3" onKeyUp={eventoEnterDatos}>
                        <Form.Control type="Number" placeholder="Validez Token" defaultValue={validez} />
                    </FloatingLabel>
                </Modal.Body>
                <Modal.Footer className='bg-dark text-white'>
                    <Stack gap={2} className="col-md-5 mx-auto">
                        <Button variant="outline-primary" onClick={guardarDatos}>
                            <FontAwesomeIcon icon={faCheck} /> Guardar
                        </Button>
                        <Button variant="outline-secondary" onClick={cancelarDatos}>
                            <FontAwesomeIcon icon={faTimes} /> Cancelar
                        </Button>
                    </Stack>
                </Modal.Footer>
            </Modal>

            <Modal show={modalRol} onHide={cancelarRol} backdrop="static" size='lg' keyboard={false}>
                <Modal.Header closeButton className='bg-dark text-white'>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faUserGear} /> Roles ({nombreSistema})
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-dark text-white'>
                    <InputGroup className="mt-3 mb-3" size='lg'>
                        <Form.Control placeholder='Nuevo Rol' id='txtRol' />
                        <Button variant='outline-success' onClick={() => {
                            agregarRol();
                        }}>
                            <FontAwesomeIcon icon={faPlus} />Agregar
                        </Button>
                        <Button variant='outline-danger' disabled={posicionRol === -1} onClick={() => {
                            quitarRol();
                        }}>
                            <FontAwesomeIcon icon={faTrash} />Quitar
                        </Button>
                        <Button variant='outline-primary' disabled={posicionRol === -1} onClick={() => {
                            manejarInterfaz();
                        }}>
                            <FontAwesomeIcon icon={faWindowRestore} />Interfaz
                        </Button>
                    </InputGroup>
                    <ListGroup as="ol" numbered>
                        {
                            roles.map((rol, llave) => (
                                <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" active={llave === posicionRol} action={true} key={llave} onClick={() => {
                                    setPosicionRol(llave);
                                }}>
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">{rol.nombre}</div>
                                        Seleccione para interactuar
                                    </div>
                                    <Badge bg="black" pill>
                                        Interfaz: {rol.interfaz.length}
                                    </Badge>
                                </ListGroup.Item>
                            ))
                        }
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer className='bg-dark text-white'>
                    <Stack gap={2} className="col-md-5 mx-auto">
                        <Button variant="outline-primary" onClick={guardarRol}>
                            <FontAwesomeIcon icon={faCheck} /> Guardar
                        </Button>
                        <Button variant="outline-secondary" onClick={cancelarRol}>
                            <FontAwesomeIcon icon={faTimes} /> Cancelar
                        </Button>
                    </Stack>
                </Modal.Footer>
            </Modal>

            <Modal show={modalInterfaz} onHide={cancelarInterfaz} backdrop="static" size='lg' keyboard={false}>
                <Modal.Header closeButton className='bg-dark text-white'>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faWindowRestore} /> Interfaces ({nombreRol})
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-dark text-white'>
                    <InputGroup className="mt-3 mb-3" size='lg'>
                        <Form.Control placeholder='Nueva Interfaz' id='txtInterfaz' />
                        <Button variant='outline-success' onClick={() => {
                            agregarInterfaz();
                        }}>
                            <FontAwesomeIcon icon={faPlus} />Agregar
                        </Button>
                        <Button variant='outline-danger' disabled={posicionInterfaz === -1} onClick={() => {
                            quitarInterfaz();
                        }}>
                            <FontAwesomeIcon icon={faTrash} />Quitar
                        </Button>
                        <Button variant='outline-primary' disabled={posicionInterfaz === -1} onClick={() => {
                            manejarPermiso();
                        }}>
                            <FontAwesomeIcon icon={faCertificate} />Permiso
                        </Button>
                    </InputGroup>
                    <ListGroup as="ol" numbered>
                        {
                            interfaces.map((interfaz, llave) => (
                                <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" active={llave === posicionInterfaz} action={true} key={llave} onClick={() => {
                                    setPosicionInterfaz(llave);
                                }}>
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">{interfaz.nombre}</div>
                                        Seleccione para interactuar
                                    </div>
                                    <Badge bg="black" pill>
                                        Permisos: {interfaz.permiso.length}
                                    </Badge>
                                </ListGroup.Item>
                            ))
                        }
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer className='bg-dark text-white'>
                    <Stack gap={2} className="col-md-5 mx-auto">
                        <Button variant="outline-primary" onClick={guardarInterfaz}>
                            <FontAwesomeIcon icon={faCheck} /> Guardar
                        </Button>
                        <Button variant="outline-secondary" onClick={cancelarInterfaz}>
                            <FontAwesomeIcon icon={faTimes} /> Cancelar
                        </Button>
                    </Stack>
                </Modal.Footer>
            </Modal>

            <Modal show={modalPermiso} onHide={cancelarPermiso} backdrop="static" size='lg' keyboard={false}>
                <Modal.Header closeButton className='bg-dark text-white'>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faCertificate} /> Permiso ({nombreInterfaz})
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-dark text-white'>
                    <InputGroup className="mt-3 mb-3" size='lg'>
                        <Form.Control placeholder='Nuevo Permiso' id='txtPermiso' />
                        <Button variant='outline-success' onClick={() => {
                            agregarPermiso();
                        }}>
                            <FontAwesomeIcon icon={faPlus} />Agregar
                        </Button>
                        <Button variant='outline-danger' disabled={posicionPermiso === -1} onClick={() => {
                            quitarPermiso();
                        }}>
                            <FontAwesomeIcon icon={faTrash} />Quitar
                        </Button>
                    </InputGroup>
                    <ListGroup as="ol" numbered>
                        {
                            permisos.map((permiso, llave) => (
                                <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start" active={llave === posicionPermiso} action={true} key={llave} onClick={() => {
                                    setPosicionPermiso(llave);
                                }}>
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">{permiso}</div>
                                        Seleccione para interactuar
                                    </div>
                                    <Badge bg="success" pill>
                                        Habilitado
                                    </Badge>
                                </ListGroup.Item>
                            ))
                        }
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer className='bg-dark text-white'>
                    <Stack gap={2} className="col-md-5 mx-auto">
                        <Button variant="outline-primary" onClick={guardarPermiso}>
                            <FontAwesomeIcon icon={faCheck} /> Guardar
                        </Button>
                        <Button variant="outline-secondary" onClick={cancelarPermiso}>
                            <FontAwesomeIcon icon={faTimes} /> Cancelar
                        </Button>
                    </Stack>
                </Modal.Footer>
            </Modal>
        </>
    );
}