import {
    useState,
    useEffect
} from 'react';

import {
    urlBackend
} from '../recursos/configuracion';

import Axios from 'axios';
import {
    Container,
    Col,
    Row,
    Dropdown,
    Table,
    Button,
    Modal,
    FloatingLabel,
    Form,
    Stack
} from 'react-bootstrap';
import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome';
import {
    faCogs,
    faPlus,
    faRefresh,
    faUserSecret,
    faCertificate,
    faEdit,
    faLock,
    faCog,
    faUserPlus,
    faTimes,
    faCheck,
    faUserGear,
    faTrash
} from '@fortawesome/free-solid-svg-icons';
import {
    Cargador
} from '../componentes/Cargador';
import {
    reportar
} from '../recursos/funcion';
import Swal from 'sweetalert2';
import {
    useCuentaContexto
} from '../proveedor/cuenta';

export default function Cuentas() {

    const cuenta = useCuentaContexto();

    const [datos, setDatos] = useState([]);
    const [sistemas, setSistemas] = useState([]);

    const [listas, setListas] = useState([]);

    const [sublistas, setSubListas] = useState([]);

    const [nombreCuenta, setNombreCuenta] = useState("");
    const [rutaCuenta, setRutaCuenta] = useState("");

    const [posicionCuenta, setPosicionCuenta] = useState(-1);

    const [carga, setCarga] = useState(false);

    const [modalDatos, setModalDatos] = useState(false);

    const mostrarModalDatos = () => setModalDatos(true);
    const cerrarModalDatos = () => setModalDatos(false);

    const [modalPermisos, setModalPermisos] = useState(false);

    const mostrarModalPermisos = () => setModalPermisos(true);
    const cerrarModalPermisos = () => setModalPermisos(false);

    useEffect(() => {
        if (datos.length > 0 && posicionCuenta >= 0) {
            setSistemas(datos[posicionCuenta].sistema);
        }
    }, [datos]);

    useEffect(() => {
        if (listas.length > 0) {
            setSubListas(listas[0].rol);
        }
    }, [listas]);

    useEffect(() => {
        cargarLista();
        recuperar();
    }, [cuenta]);

    async function recuperar() {
        if (cuenta.token !== "") {
            setCarga(true);
            try {
                let respuesta = await Axios.get(`${urlBackend}/cuentas`, {
                    headers: {
                        Authorization: `Bearer ${cuenta.token}`
                    }
                });
                setDatos(respuesta.data.arrayJson);
                setCarga(false);
            } catch (error) {
                setCarga(false);
                await reportar('Sin definir', "Ecommerce Auth APP", "Frontend", "cuentas.jsx", "recuperar", `${error.toString()}`, {
                    error
                }, 1);
            }
        }
    }

    async function cargarLista() {
        if (cuenta.token !== "") {
            try {
                let respuesta = await Axios.get(`${urlBackend}/sistemas`, {
                    headers: {
                        Authorization: `Bearer ${cuenta.token}`
                    }
                });
                setListas(respuesta.data.arrayJson);
            } catch (error) {
                await reportar('Sin definir', "Ecommerce Auth APP", "Frontend", "cuentas.jsx", "cargarLista", `${error.toString()}`, {
                    error
                }, 1);
            }
        }
    }

    function nuevo() {
        mostrarModalDatos();
    }

    async function guardarDatos() {
        try {
            let nombre = document.getElementById('txtNombre').value;
            let apellido = document.getElementById('txtApellido').value;
            let correo = document.getElementById('txtCorreo').value;
            let contrasena = document.getElementById('txtContrasena').value;
            if (nombre === "" || apellido === "" || correo === "" || contrasena === "") {

            } else {
                let parametros = {
                    nombre: nombre,
                    apellido: apellido,
                    correo: correo,
                    contrasena: contrasena
                }
                await Axios.post(`${urlBackend}/cuentas`, parametros, {
                    headers: {
                        Authorization: `Bearer ${cuenta.token}`
                    }
                });
                recuperar();
                cerrarModalDatos();
            }
        } catch (error) {
            await reportar('Sin definir', "Ecommerce Auth APP", "Frontend", "cuentas.jsx", "guardarDatos", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    function cancelarDatos() {
        cerrarModalDatos();
    }

    async function agregarPermiso() {
        if (document.getElementById('cbRol').value === "") {

        } else {
            var selectSistema = document.getElementById("cbSistema");
            var optionSeleccionadaSistema = selectSistema.selectedOptions[0];
            var contenidoHTMLSistema = optionSeleccionadaSistema.innerHTML;

            var selectRol = document.getElementById("cbRol");
            var optionSeleccionadaRol = selectRol.selectedOptions[0];
            var contenidoHTMLRol = optionSeleccionadaRol.innerHTML;
            try {
                let parametros = {
                    rol: {
                        nombre: contenidoHTMLSistema,
                        direccion: `${listas[document.getElementById('cbSistema').value].protocolo}//${listas[document.getElementById('cbSistema').value].direccion}${(listas[document.getElementById('cbSistema').value].puerto === null ? '' : `:${listas[document.getElementById('cbSistema').value].puerto}`)}`,
                        rol: {
                            codigo: document.getElementById('cbRol').value,
                            nombre: contenidoHTMLRol
                        }
                    }
                }
                let respuesta = await Axios.post(`${urlBackend}/rol?ruta=${rutaCuenta}`, parametros, {
                    headers: {
                        Authorization: `Bearer ${cuenta.token}`
                    }
                });
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'info',
                    title: respuesta.data.descripcion
                })
                recuperar();
            } catch (error) {
                await reportar('Sin definir', "Ecommerce Auth APP", "Frontend", "cuentas.jsx", "agregarPermiso", `${error.toString()}`, {
                    error
                }, 1);
            }
        }
    }

    async function quitarPermiso(direccion) {
        try {
            let parametros = {
                rol: {
                    direccion: direccion
                }
            }
            let respuesta = await Axios.put(`${urlBackend}/rol?ruta=${rutaCuenta}`, parametros, {
                headers: {
                    Authorization: `Bearer ${cuenta.token}`
                }
            });
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'info',
                title: respuesta.data.descripcion
            })
            recuperar();
        } catch (error) {
            await reportar('Sin definir', "Ecommerce Auth APP", "Frontend", "cuentas.jsx", "quitarPermiso", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    async function guardarPermisos() {

    }

    function cancelarPermisos() {
        cerrarModalPermisos();
    }

    async function editar() {

    }

    async function manejarPermisos(posicion, ruta, nombre, apellido, sistema) {
        setPosicionCuenta(posicion);
        setSistemas(sistema);
        setRutaCuenta(ruta);
        setNombreCuenta(`${nombre} ${apellido}`);
        mostrarModalPermisos();
    }

    async function cambiarContrasena() {

    }

    async function estado(ruta, estado) {
        try {
            let parametros = {
                estado: (estado === 0 ? 1 : 0)
            }
            let respuesta = await Axios.put(`${urlBackend}/cuentas?ruta=${ruta}`, parametros, {
                headers: {
                    Authorization: `Bearer ${cuenta.token}`
                }
            });
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'info',
                title: respuesta.data.descripcion
            })
            recuperar();
        } catch (error) {
            await reportar('Sin definir', "Ecommerce Auth APP", "Frontend", "cuentas.jsx", "estado", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    const eventoEnterDatos = (event) => {
        if (event.key === 'Enter') {
            guardarDatos();
        }
    }

    const eventoEnterPermisos = (event) => {
        if (event.key === 'Enter') {
            guardarPermisos();
        }
    }

    return (
        <>
            <Container fluid>
                <Row className='mt-3 mb-3'>
                    <Col md={12}>
                        <h3>
                            {(carga === true ? <Cargador estado={true} /> : <FontAwesomeIcon icon={faUserSecret} />)}Cuentas
                        </h3>
                    </Col>
                    <Col md={12} className='mt-3 mb-3'>
                        <Dropdown data-bs-theme="dark">
                            <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                                <FontAwesomeIcon icon={faCogs} />Opciones
                            </Dropdown.Toggle>
                            <Dropdown.Menu variant="dark">
                                <Dropdown.Item onClick={nuevo}>
                                    <FontAwesomeIcon icon={faPlus} />Nuevo
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={recuperar}>
                                    <FontAwesomeIcon icon={faRefresh} />Actualizar
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col md={12} className='table-responsive'>
                        <Table bordered className='text-white'>
                            <thead>
                                <tr>
                                    <th>Acción</th>
                                    <th>Nombre</th>
                                    <th>Apellido</th>
                                    <th>Usuario</th>
                                    <th>Correo</th>
                                    <th>Roles</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    datos.map((dato, llave) => (
                                        <tr key={llave} className={(dato.estado === 0 ? 'text-danger' : 'text-success')}>
                                            <td>
                                                <Dropdown data-bs-theme="dark">
                                                    <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                                                        <FontAwesomeIcon icon={faCog} />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu variant="dark">
                                                        <Dropdown.Item onClick={() => {
                                                            manejarPermisos(llave, dato.ruta, dato.nombre, dato.apellido, dato.sistema);
                                                        }}>
                                                            <FontAwesomeIcon icon={faCertificate} />Permisos
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={() => {
                                                            editar(dato.ruta);
                                                        }} disabled>
                                                            <FontAwesomeIcon icon={faEdit} />Editar
                                                        </Dropdown.Item>
                                                        <Dropdown.Item onClick={() => {
                                                            cambiarContrasena(dato.ruta);
                                                        }} disabled>
                                                            <FontAwesomeIcon icon={faLock} />Cambiar contraseña
                                                        </Dropdown.Item>
                                                        <Dropdown.Divider />
                                                        <Dropdown.Item onClick={() => {
                                                            estado(dato.ruta, dato.estado);
                                                        }}>
                                                            <FontAwesomeIcon icon={faRefresh} />Estado
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                            <td>{dato.nombre}</td>
                                            <td>{dato.apellido}</td>
                                            <td>{dato.usuario}</td>
                                            <td>{dato.correo}</td>
                                            <td>{dato.sistema.length}</td>
                                            <td>{(dato.estado === 1 ? <><FontAwesomeIcon icon={faCheck} />Activo</> : <><FontAwesomeIcon icon={faTimes} />Inactivo</>)}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
            <Modal show={modalDatos} onHide={cancelarDatos} backdrop="static" keyboard={false}>
                <Modal.Header closeButton className='bg-dark text-white'>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faUserPlus} /> Cuenta
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-dark text-white'>
                    <FloatingLabel controlId="txtNombre" label="Nombre" className="text-dark mb-3" onKeyUp={eventoEnterDatos}>
                        <Form.Control type="text" placeholder="Nombre" defaultValue="" />
                    </FloatingLabel>
                    <FloatingLabel controlId="txtApellido" label="Apellido" className="text-dark mb-3" onKeyUp={eventoEnterDatos}>
                        <Form.Control type="text" placeholder="Apellido" defaultValue="" />
                    </FloatingLabel>
                    <FloatingLabel controlId="txtCorreo" label="Correo" className="text-dark mb-3" onKeyUp={eventoEnterDatos}>
                        <Form.Control type="email" placeholder="Correo" defaultValue="" />
                    </FloatingLabel>
                    <FloatingLabel controlId="txtContrasena" label="Contraseña" className="text-dark mb-3" onKeyUp={eventoEnterDatos}>
                        <Form.Control type="password" placeholder="Contraseña" defaultValue="" />
                    </FloatingLabel>
                </Modal.Body>
                <Modal.Footer className='bg-dark text-white'>
                    <Stack gap={2} className="col-md-5 mx-auto">
                        <Button variant="outline-primary" onClick={guardarDatos}>
                            <FontAwesomeIcon icon={faCheck} /> Guardar
                        </Button>
                        <Button variant="outline-secondary" onClick={cancelarDatos}>
                            <FontAwesomeIcon icon={faTimes} /> Cancelar
                        </Button>
                    </Stack>
                </Modal.Footer>
            </Modal>

            <Modal show={modalPermisos} onHide={cancelarPermisos} backdrop="static" size='lg' keyboard={false}>
                <Modal.Header closeButton className='bg-dark text-white'>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faUserGear} /> Rol ({nombreCuenta})
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-dark text-white'>
                    <Row>
                        <Col>
                            <FloatingLabel controlId="cbSistema" label="Sistema" className='text-dark mt-3 mb-3' onChange={() => {
                                setSubListas(listas[document.getElementById('cbSistema').value].rol);
                            }}>
                                <Form.Select aria-label="Floating label select example">
                                    {
                                        listas.map((lista, llave) => (
                                            <option value={llave} key={llave}>{lista.nombre}</option>
                                        ))
                                    }
                                </Form.Select>
                            </FloatingLabel>
                            <FloatingLabel controlId="cbRol" label="Rol" className='text-dark mt-3 mb-3'>
                                <Form.Select aria-label="Floating label select example">
                                    {
                                        sublistas.map((sublista, llave) => (
                                            <option value={sublista.codigo} key={llave}>{sublista.nombre}</option>
                                        ))
                                    }
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                        <Col md={12}>
                            <Button variant='outline-primary' size='lg' onClick={() => {
                                agregarPermiso();
                            }}>
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                            <Table bordered className='text-white mt-3'>
                                <thead>
                                    <tr>
                                        <th>Acción</th>
                                        <th>Sistema</th>
                                        <th>Rol</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sistemas.map((sistema, llave) => (
                                            <tr key={llave}>
                                                <td>
                                                    <Button variant='outline-danger' onClick={() => {
                                                        quitarPermiso(sistema.direccion);
                                                    }}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </td>
                                                <td>{sistema.nombre}</td>
                                                <td>{sistema.rol.nombre}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='bg-dark text-white'>
                    <Stack gap={2} className="col-md-5 mx-auto">
                        <Button variant="outline-primary" onClick={cancelarPermisos}>
                            <FontAwesomeIcon icon={faCheck} /> Hecho
                        </Button>
                    </Stack>
                </Modal.Footer>
            </Modal>
        </>
    );
}