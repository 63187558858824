import {
    useState,
    useEffect
} from 'react';
import {
    useLocation,
    useNavigate
} from 'react-router-dom';
import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome';
import {
    faCode,
    faHome,
    faRightFromBracket,
    faTag,
    faUser,
    faUserSecret
} from '@fortawesome/free-solid-svg-icons';
import {
    Container,
    Navbar,
    Nav
} from 'react-bootstrap';
import {
    useSetCuentaContexto,
    useCuentaContexto
} from '../proveedor/cuenta';

import Axios from 'axios';
import {
    urlBackend
} from '../recursos/configuracion';
import {
    obtenerCuenta,
    reportar
} from '../recursos/funcion';
import Swal from 'sweetalert2';

export function BarraSuperior() {
    const cuenta = useCuentaContexto();
    const [cuentaActiva, setCuentaActiva] = useState(false);
    const establecerCuenta = useSetCuentaContexto();
    const localizacion = useLocation();
    const navegar = useNavigate();

    useEffect(() => {
        setInterval(() => {
            verificarToken(1);
        }, 25000);
    }, []);

    useEffect(() => {
        verificarToken(0);
    }, [localizacion]);

    async function verificarToken(tipo) {
        let token = sessionStorage.getItem('auth-token');
        if (token === null || token === "") {
            if (localizacion.pathname !== '/acceso') {
                navegar('/acceso');
            }
        } else {
            if (await validarToken(token)) {
                if (tipo === 0) {
                    if (localizacion.pathname === '/acceso') {
                        navegar('/portada');
                    }
                }
            } else {
                navegar('/acceso');
            }
        }
    }

    async function validarToken(token) {
        let envio = false;
        try {
            let respuesta = await Axios.post(`${urlBackend}/comprobar`, {

            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (respuesta.data.codigo === 1) {
                const cuentaToken = obtenerCuenta(token);
                establecerCuenta(
                    {
                        token: token,
                        nombre: cuentaToken.nombre,
                        apellido: cuentaToken.apellido,
                        rol: cuentaToken.rol.nombre,
                        interfaces: cuentaToken.rol.interfaz
                    }
                );
                envio = true;
            } else {
                sessionStorage.removeItem('auth-token');
                establecerCuenta(
                    {
                        token: '',
                        nombre: '',
                        apellido: '',
                        rol: '',
                        interfaces: []
                    }
                );
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'info',
                    title: respuesta.data.descripcion
                })
                envio = false;
            }
            setCuentaActiva(envio);
        } catch (error) {
            console.log(error);
            await reportar('Sin definir', "Ecommerce Auth APP", "Frontend", "BarraSuperior.jsx", "validarToken", `${error.toString()}`, {
                error
            }, 1);
        }
        return envio;
    }

    async function revocarToken() {
        let token = sessionStorage.getItem('auth-token');
        let respuesta = await Axios.delete(`${urlBackend}/cerrar`, {
            headers: {
                authorization: `Bearer ${token}`
            }
        });
        if (respuesta.data.codigo === 0) {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: 'success',
                title: respuesta.data.descripcion
            })
            sessionStorage.removeItem('auth-token');
            setCuentaActiva(false);
            establecerCuenta(
                {
                    token: '',
                    nombre: '',
                    apellido: '',
                    rol: '',
                    interfaces: []
                }
            );
            verificarToken();
        }
    }

    return (
        <>
            <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary bg-dark">
                <Container fluid>
                    <Navbar.Brand className='text-white'>
                        <FontAwesomeIcon icon={faTag} /> Central Shop - Auth APP
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        {
                            cuentaActiva ? <>
                                <Nav className="me-auto">
                                    <Nav.Link className='text-white' onClick={() => {
                                        if (cuentaActiva) {
                                            navegar('/portada');
                                        }
                                    }}>
                                        <FontAwesomeIcon icon={faHome} /> Portada
                                    </Nav.Link>
                                    {
                                        (cuenta.interfaces.findIndex((interfaz) => interfaz.nombre === 'cuentas') >= 0 ?
                                            <Nav.Link className='text-white' onClick={() => {
                                                navegar('/cuentas');
                                            }}>
                                                <FontAwesomeIcon icon={faUserSecret} /> Cuentas
                                            </Nav.Link> : '')
                                    }
                                    {
                                        (cuenta.interfaces.findIndex((interfaz) => interfaz.nombre === 'sistemas') >= 0 ?
                                            <Nav.Link className='text-white' onClick={() => {
                                                navegar('/sistemas');
                                            }}>
                                                <FontAwesomeIcon icon={faCode} /> Sistemas
                                            </Nav.Link> : '')
                                    }
                                </Nav>
                                <Nav>
                                    <Navbar.Text className='text-white'>
                                        <FontAwesomeIcon icon={faUser} /> {(cuentaActiva ? `${cuenta.nombre} ${cuenta.apellido} (${cuenta.rol})` : '')}
                                    </Navbar.Text>
                                    <Nav.Link className='text-white' onClick={() => {
                                        if (cuentaActiva) {
                                            revocarToken();
                                        }
                                    }}>
                                        <FontAwesomeIcon icon={faRightFromBracket} />Cerrar Cuenta
                                    </Nav.Link>
                                </Nav>
                            </> : ''
                        }
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}