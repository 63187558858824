import {
    faCertificate,
    faCode,
    faDiagramProject,
    faInfoCircle,
    faUserSecret,
    faWindowRestore
} from '@fortawesome/free-solid-svg-icons';
import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome';
import {
    Container,
    Col,
    Row,
    Card
} from 'react-bootstrap';

export default function Portada() {

    return (
        <>
            <Container>
                <Row>
                    <Col md={6}>
                        <Card className='bg-dark text-white mb-3 mt-3 mr-3 ml-3'>
                            <Card.Body>
                                <Card.Title>
                                    <h1>
                                        <FontAwesomeIcon icon={faUserSecret} />Cuentas
                                    </h1>
                                </Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">
                                    Cuentas de usuario reutilizable entre sistemas.
                                </Card.Subtitle>
                                <Card.Text>
                                    Se proceden crear cuentas para luego asignarles un rol por Sistema.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card className='bg-dark text-white mb-3 mt-3 mr-3 ml-3'>
                            <Card.Body>
                                <Card.Title>
                                    <h1>
                                        <FontAwesomeIcon icon={faCode} />Sistemas
                                    </h1>
                                </Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">
                                    Contiene una lista de sistemas disponibles.
                                </Card.Subtitle>
                                <Card.Text>
                                    Se procede a crear el sistema, seguido de sus roles, seguido de las interfaces. Opcional: Se puede personalizar los permisos.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card className='bg-dark text-white mb-3 mt-3 mr-3 ml-3'>
                            <Card.Body>
                                <Card.Title>
                                    <h1>
                                        <FontAwesomeIcon icon={faWindowRestore} />Interfaz
                                    </h1>
                                </Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">
                                    Contienen una lista de interfaces disponibles en el sistema.
                                </Card.Subtitle>
                                <Card.Text>
                                    Se procede a crear la interfaz, cada interfaz se crea con permisos basicos por defecto, basado en CRUD (crear, leer, modificar y eliminar).
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card className='bg-dark text-white mb-3 mt-3 mr-3 ml-3'>
                            <Card.Body>
                                <Card.Title>
                                    <h1>
                                        <FontAwesomeIcon icon={faCertificate} />Permiso
                                    </h1>
                                </Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">
                                    Contiene una lista de permisos de la interfaz seleccionada, estos permisos se pueden personalizar.
                                </Card.Subtitle>
                                <Card.Text>
                                    Se procede a crear el permiso para la interfaz.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={12}>
                        <Card className='bg-dark text-white mb-3 mt-3 mr-3 ml-3'>
                            <Card.Body>
                                <Card.Title>
                                    <h1>
                                        <FontAwesomeIcon icon={faInfoCircle} />Dato de vital importancia
                                    </h1>
                                </Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">
                                    Esta información será enviada en forma de una JSON Web Token al sistema registrado y autorizado.
                                </Card.Subtitle>
                                <Card.Text>
                                    El token deberá ser entregado a su API para decifrarlo y obtener los permisos del usuario.<br />
                                    Todas las peticiones deben ir con el encabezado 'authorization', tipo 'Bearer', seguido del token o un 0.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={12}>
                        <Card className='bg-dark text-white mb-3 mt-3 mr-3 ml-3'>
                            <Card.Body>
                                <Card.Title>
                                    <h1>
                                        <FontAwesomeIcon icon={faDiagramProject} />Flujo
                                    </h1>
                                </Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">
                                    Peticiones y Respuestas.
                                </Card.Subtitle>
                                <ol>
                                    <li>
                                        /acceso: Provee el token de autorizacion.
                                    </li>
                                    <li>
                                        /comprobar: Devuelve datos de usuario, rol y los permisos para el frontend.
                                    </li>
                                    <li>
                                        /cerrar: Revoca el token de autorizacion.
                                    </li>
                                </ol>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}