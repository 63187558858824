import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './estilo/General.css';
import Sistemas from './paginas/sistemas';
import Cuentas from './paginas/cuentas';
import Acceso from './paginas/acceso';
import Portada from './paginas/portada';
import {
  CuentaProveedor
} from './proveedor/cuenta';
import {
  BarraSuperior
} from './componentes/BarraSuperior';

function App() {

  return (
    <BrowserRouter>
      <CuentaProveedor>
        <BarraSuperior />
        <Routes>
          <Route path='/' index element={<Navigate to='/acceso' />} />
          <Route path='/acceso' index element={<Acceso />} />
          <Route path='/portada' index element={<Portada />} />
          <Route path='/sistemas' index element={<Sistemas />} />
          <Route path='/cuentas' index element={<Cuentas />} />
        </Routes>
      </CuentaProveedor>
    </BrowserRouter>
  );
}

export default App;