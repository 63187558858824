import {
    useEffect
} from 'react';

import {
    useNavigate
} from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Card,
    Form,
    Stack,
    FloatingLabel,
    Button
} from 'react-bootstrap';

import {
    FontAwesomeIcon
} from '@fortawesome/react-fontawesome';

import {
    faUser,
    faRightToBracket
} from '@fortawesome/free-solid-svg-icons';

import Axios from 'axios';

import {
    urlBackend
} from '../recursos/configuracion';

import {
    reportar
} from '../recursos/funcion';
import Swal from 'sweetalert2';

export default function Acceso() {

    const navegar = useNavigate();

    async function iniciar() {
        try {
            let usuario = document.getElementById('txtUsuario').value;
            let contrasena = document.getElementById('txtContrasena').value;
            if (usuario === "" || contrasena === "") {

            } else {
                let parametros = {
                    usuario,
                    contrasena
                }
                let respuesta = await Axios.post(`${urlBackend}/acceso`, parametros,
                    {
                        headers: {
                            Authorization: 'Bearer 0'
                        }
                    }
                );
                if (respuesta.data.codigo === 1) {
                    sessionStorage.setItem('auth-token', respuesta.headers['authorization'].split(' ')[1]);
                    navegar('/portada');
                } else {
                    sessionStorage.removeItem('auth-token');
                }
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'info',
                    title: respuesta.data.descripcion
                })
            }
        } catch (error) {
            await reportar('Sin definir', "Ecommerce Auth APP", "Frontend", "acceso.jsx", "iniciar", `${error.toString()}`, {
                error
            }, 1);
        }
    }

    const eventoEnter = (event) => {
        if (event.key === 'Enter') {
            iniciar();
        }
    }

    return (
        <>
            <Container className="d-flex justify-content-center align-items-center vh-100">
                <Card className='bg-dark text-white'>
                    <Card.Body>
                        <Card.Title><FontAwesomeIcon icon={faUser} />Iniciar sesión</Card.Title>
                        <Row>
                            <Col md={12}>
                                <FloatingLabel controlId="txtUsuario" label="Usuario" className="text-dark mb-3 mt-3" onKeyUp={eventoEnter}>
                                    <Form.Control type="text" placeholder="Usuario" />
                                </FloatingLabel>
                                <FloatingLabel controlId="txtContrasena" label="Contraseña" className="text-dark mb-3 mt-3" onKeyUp={eventoEnter}>
                                    <Form.Control type="password" placeholder="Contraseña" />
                                </FloatingLabel>
                            </Col>
                            <Col md={12}>
                                <Stack gap={2} className="mx-auto">
                                    <Button variant="outline-primary" onClick={iniciar}>
                                        <FontAwesomeIcon icon={faRightToBracket} />Ingresar
                                    </Button>
                                </Stack>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </>
    );
}