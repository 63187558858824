
import {
    urlSyslogBackend,
    ambienteApp
} from './configuracion';
import Axios from "axios";

export function generarCodigoAlfanumerico(cantidad) {
    let caracteres = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let caracteresLongitud = cantidad;
    let resultado = '';
    for (var i = 0; i < caracteresLongitud; i++) {
        resultado += caracteres.charAt(Math.floor(Math.random() * caracteresLongitud));
    }
    return resultado;
}

export async function reportar(usuario, programa, lado, archivo, funcion, descripcion, detalle, tipo) {
    try {
        var parametros = {
            ambiente: ambienteApp,
            usuario: usuario,
            programa: programa,
            lado: lado,
            archivo: archivo,
            funcion: funcion,
            descripcion: descripcion,
            detalle: detalle,
            tipo: tipo
        }
        let respuesta = await Axios.post(`${urlSyslogBackend}/registros`, parametros);
        console.log(respuesta);
    } catch (error) {
        console.log(error);
    }
}

export function obtenerCuenta(token) {
    const cargaUtil = token.split('.')[1];
    const descifrado = JSON.parse(atob(cargaUtil));
    return descifrado;
}